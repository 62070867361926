import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Container from "components/container"
import WebinarForm from "components/webinar/form"
import WebinarPresenter from "components/webinar/presenter"
import WebinarContainer from "components/webinar/form-container"
import FeaturedPage from "components/webinar/featured-page"

const FORM_ID = process.env.GATSBY_FORM_WEBINAR_2022_09_27_TRENT

const FormFull = props => {

	return (
		<WebinarContainer
			viewAllowed={props.viewAllowed}
		>
			<WebinarPresenter>
				<Container data-aos="fade-up">
					{props.featured === true ?
						<FeaturedPage
							videoDisplayTitle={props.title}
							featured={props.featured}
							featuredDate={props.featuredDate}
							featuredTime={props.featuredTime}
							featuredCost={props.featuredCost}
							featuredUrl={props.featuredUrl}
							presenter={props.presenter}
							presenterSubtitle={props.presenterSubtitle}
							extraMessage={props.extraMessage}
						/>
						:
						<React.Fragment>
							<WebinarForm
								videoDisplayTitle="Importance of Rapid STI Testing for Sexually Active Adolescent Girls"
								localStorageID="2022-09-27-trent-webinar-replay"
								pageSlug="/webinar-replay/2022-09-27-trent-view/"
								wordpressFormID={FORM_ID}
							/>
						</React.Fragment>
					}
					{props.featured === true ? null :
						<div className="right__column">
							<h3>Video</h3>
							<StaticImage
								src="../../../../assets/images/webinar-replay/2022-09-27-trent-webinar-replay.jpg"
								width={1260}
								alt="Video replay image"
								className="videoReplay"
							/>

							<h3>Presenter</h3>
							<div className="columnPresenter">
								<StaticImage
									src="../../../../assets/images/webinar-replay/maria-trent.jpg"
									width={200}
									alt="Dr. Maria Trent"
									className="presenter__image"
								/>
								<p><span className="title">Maria Trent, MD, MPH, FAAP, FSAHM</span><br />
								Johns Hopkins University
								</p>
							</div>
						</div>
					}
				</Container>
			</WebinarPresenter>
		</WebinarContainer>
	)
}

export default FormFull
